import { configureStore } from '@reduxjs/toolkit'

import agentCreationReducer from './features/agents/agentCreationSlice'
import agentJobsReducer from './features/agent-jobs/agentJobsSlice'
import datasetsReducer from './features/datasets/datasetsSlice'
import datasetFilesReducer from './features/datasets/datasetFilesSlice'
import workspaceStateReducer from './features/workspace/workspaceStateSlice'
import queryResultReducer from './features/workspace/queryResultSlice'

export const makeStore = () => {
  return configureStore({
    reducer: {
      agentCreation: agentCreationReducer,
      agentJobs: agentJobsReducer,
      datasets: datasetsReducer,
      datasetFiles: datasetFilesReducer,
      workspaceState: workspaceStateReducer,
      queryResult: queryResultReducer,
    },
  })
}

// Infer the type of makeStore
export type AppStore = ReturnType<typeof makeStore>
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<AppStore['getState']>
export type AppDispatch = AppStore['dispatch']
